import { Box, Button, MenuItem, TextField, Select, SelectChangeEvent, Rating, Stack, Container, FormControl, InputLabel } from "@mui/material";
import React, { useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { LazyReview, Owner, Property, Review, ReviewType } from "../../models";
import { DataStore, ModelInit } from "@aws-amplify/datastore";

interface CreateReviewFormProps {
    toggleShowReviewPage: () => void;
}

export function CreateReviewForm(props: CreateReviewFormProps) {
    const [formReview, setFormReview] = useState<Partial<Review>>();
    const [formOwner, setFormOwner] = useState<Partial<Owner>>();
    const [formProperty, setFormProperty] = useState<Partial<Property>>();

    // type Review @model @auth(rules: [{allow: public}]) {
    //   id: ID!
    //   rating: Int!
    //   type: ReviewType
    //   images: [String]
    //   text: String
    //   propertyID: ID @index(name: "byProperty")
    //   ownerID: ID @index(name: "byOwner")
    //   streetNumber: Int
    //   streetName: String
    //   suburb: String
    //   city: String
    //   country: String
    // }

    /*
     * Property Review:
     * ----------------
     *
     * Search for Property
     * If exists use its ID otherwise create and use its ID
     * Create a review using the review information and the propertyId
     */

    /*
     * AREA Review:
     * ------------
     *
     * Allow this or rather force this to be property specific?
     * Maybe keep property specific to start to make it simple
     * Remove the "Area" idea from the dropdown
     */

    /*
     * Owner Review:
     * -------------
     *
     * Need to handle multiple owners with same name
     *
     * Owner reviews should be added with a property.
     * You should not be able to add a review of an owner without
     * attaching a specific property.
     * This can maybe be added back later.
     *
     * Search for a Property
     * If exists use its ID otherwise create and use its ID
     *
     * Search for an Owner
     * If exists use its ID otherwise create and use its ID
     *
     * Create a review using the review information and the propertyId and ownerIds
     */

    /*
     * Agent Review:
     * -------------
     *
     * Agents represented with owner type
     *
     * Owner reviews should be added with a property.
     * You should not be able to add a review of an owner without
     * attaching a specific property.
     * This can maybe be added back later.
     *
     * Search for a Property
     * If exists use its ID otherwise create and use its ID
     *
     * Search for an Owner
     * If exists use its ID otherwise create and use its ID
     *
     * Create a review using the review information and the propertyId and ownerIds
     */

    return (
        <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
            <Button
                variant="contained"
                onClick={async () => {
                    props.toggleShowReviewPage();
                }}
            >
                Back
            </Button>
            <Button
                variant="contained"
                onClick={async () => {
                    console.log("GOing to create this thing ", formReview);
                    if (formReview && formReview?.rating && formReview.text && formReview.type) {
                        console.log("Creating   ", formReview);
                        const result = await DataStore.save<LazyReview>(new Review(formReview as ModelInit<LazyReview>));
                        console.log("Result is  ", result);
                    }
                    props.toggleShowReviewPage();
                }}
            >
                Create
            </Button>
            <Button variant="contained">Update</Button>
            <Button variant="contained">Delete</Button>
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Review Type</InputLabel>
                    <Select
                        placeholder="Review Type"
                        value={formReview?.type || ""}
                        label="Review Type"
                        onChange={(event: SelectChangeEvent<"" | ReviewType | "PROPERTY" | "LANDLORD" | "AGENT" | "AREA">) => {
                            setFormReview({ ...formReview, type: event.target.value as ReviewType });
                        }}
                    >
                        <MenuItem value={undefined}>{"Reset"}</MenuItem>
                        <MenuItem value={ReviewType.AGENT}>{ReviewType.AGENT}</MenuItem>
                        <MenuItem value={ReviewType.LANDLORD}>{ReviewType.LANDLORD}</MenuItem>
                        {/* 
                            // TODO: Add this back in later once AREA reviews are added
                            <MenuItem value={ReviewType.AREA}>{ReviewType.AREA}</MenuItem>            
                        */}
                        <MenuItem value={ReviewType.PROPERTY}>{ReviewType.PROPERTY}</MenuItem>
                    </Select>
                </FormControl>
                {formReview?.type === "AGENT" || formReview?.type === "LANDLORD" ? (
                    <>
                        <TextField
                            id="outlined-basic"
                            label="First name"
                            variant="outlined"
                            value={formOwner?.firstName || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormOwner({ ...formOwner, firstName: event.target.value || "" });
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Last Name"
                            variant="outlined"
                            value={formOwner?.lastName || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormOwner({ ...formOwner, lastName: event.target.value || "" });
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
                {formReview?.type === "AGENT" ? (
                    <>
                        <TextField
                            id="outlined-basic"
                            label="Company Name"
                            variant="outlined"
                            value={formOwner?.companyName || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormOwner({ ...formOwner, companyName: event.target.value || "" });
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
                {formReview?.type === "AREA" || formReview?.type === "PROPERTY" ? (
                    <>
                        <TextField
                            id="outlined-basic"
                            label="Street Number"
                            variant="outlined"
                            value={formReview?.streetNumber || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormReview({ ...formReview, streetNumber: event.target.value ? parseInt(event.target.value) : undefined });
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Street Name"
                            variant="outlined"
                            value={formReview?.streetName || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormReview({ ...formReview, streetName: event.target.value || "" });
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Suburb"
                            variant="outlined"
                            value={formReview?.suburb || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormReview({ ...formReview, suburb: event.target.value || "" });
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="City"
                            variant="outlined"
                            value={formReview?.city || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormReview({ ...formReview, city: event.target.value || "" });
                            }}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Country"
                            variant="outlined"
                            value={formReview?.country || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormReview({ ...formReview, country: event.target.value || "" });
                            }}
                        />
                    </>
                ) : (
                    <></>
                )}
                {formReview?.type !== undefined && formReview?.type !== null ? (
                    <>
                        {
                            // TODO: Add images here
                        }
                        <TextField
                            id="outlined-basic"
                            label="Text"
                            variant="outlined"
                            value={formReview?.text || ""}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                setFormReview({ ...formReview, text: event.target.value || "" });
                            }}
                        />
                        <Rating
                            value={formReview?.rating || 0}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setFormReview({ ...formReview, rating: newValue });
                                }
                            }}
                        ></Rating>
                    </>
                ) : (
                    <></>
                )}
            </Stack>
        </Box>
    );
}
