import { AppBar, Avatar, Stack, Card, Box, Grid, Button, CardActionArea, CardContent, CardMedia, Container, CssBaseline, IconButton, Menu, MenuItem, Pagination, TextField, Toolbar, Tooltip, Typography, useScrollTrigger, FormControlLabel, Checkbox } from "@mui/material";
import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Property, PropertyPrice } from "../../models";
import { PropertyPage } from "./PropertyPage";
import { PropertyWithOwner, queryProperties } from "../../api/QueryProperties";
import { DataStore } from "aws-amplify";
import { queryPrices } from "../../api/QueryPrices";
import { PricesComponent } from "../../components/PricesComponent";

interface PricesProps {
    ownerId?: string;
    showButtons?: boolean;
}

export function PricesPage(props: PricesProps) {
    const [page, setPage] = useState(0);
    const [formProperty, setFormProperty] = useState<Partial<Property>>();
    const [prices, setPrices] = useState<PropertyPrice[]>([]);
    const [propertyToShow, setPropertyToShow] = useState<PropertyWithOwner | undefined>(undefined);

    const [filterProperty, setFilterProperty] = useState<Partial<Property>>();

    const listPrices = useCallback(
        async (currentPage?: number) => {
            const prices: PropertyPrice[] = await queryPrices({
                //owner: props.ownerId ? { id: props.ownerId } : undefined
                owner: undefined,
                filterProperty: filterProperty as Property,
                limit: 5,
                pageNumber: currentPage === undefined ? page : currentPage,
            });
            console.log("The prices are: ", prices);
            setPrices(prices);
        },
        [filterProperty, page]
    );

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
        listPrices(value - 1);
    };

    // On page mount list the properties
    useEffect(() => {
        listPrices();
    }, [listPrices]);

    return (
        <>
            <>
                <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                    {propertyToShow ? (
                        <>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPropertyToShow(undefined);
                                }}
                            >
                                Back
                            </Button>
                            <PropertyPage property={propertyToShow} />
                            {
                                //listProperties={listProperties}
                            }
                        </>
                    ) : (
                        <>
                            {props.showButtons === undefined || props.showButtons ? (
                                <>
                                    <Grid2 container spacing={1}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                listPrices();
                                            }}
                                        >
                                            List
                                        </Button>
                                        <TextField
                                            id="outlined-basic"
                                            label="Owner ID"
                                            variant="outlined"
                                            value={filterProperty?.ownerID || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, ownerID: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Street Name"
                                            variant="outlined"
                                            value={filterProperty?.streetName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, streetName: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Street Number"
                                            variant="outlined"
                                            value={filterProperty?.streetNumber || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, streetNumber: event.target.value ? parseInt(event.target.value) : undefined });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Suburb"
                                            variant="outlined"
                                            value={filterProperty?.suburb || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, suburb: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="City"
                                            variant="outlined"
                                            value={filterProperty?.city || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, city: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Country"
                                            variant="outlined"
                                            value={filterProperty?.country || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, country: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="No Bedrooms"
                                            variant="outlined"
                                            value={filterProperty?.bedrooms || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="No Parkings"
                                            variant="outlined"
                                            value={filterProperty?.parkings || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty });
                                            }}
                                            //, parkings: event.target.value ? parseInt(event.target.value) : undefined
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked
                                                    checked={filterProperty?.furnished || false}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFilterProperty({ ...filterProperty, furnished: event.target.checked });
                                                    }}
                                                />
                                            }
                                            label="Furnished"
                                        />
                                    </Grid2>
                                    <br />
                                    <Grid2 container spacing={1}>
                                        <Button
                                            variant="contained"
                                            onClick={async () => {
                                                //await createProperty(new Property((formProperty as Property) || {}));
                                                listPrices();
                                            }}
                                        >
                                            Create
                                        </Button>
                                        <Button variant="contained">Update</Button>
                                        <Button variant="contained">Delete</Button>
                                        <TextField
                                            id="outlined-basic"
                                            label="Owner ID"
                                            variant="outlined"
                                            value={formProperty?.ownerID || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, ownerID: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Street Name"
                                            variant="outlined"
                                            value={formProperty?.streetName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, streetName: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Street Number"
                                            variant="outlined"
                                            value={formProperty?.streetNumber || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, streetNumber: event.target.value ? parseInt(event.target.value) : undefined });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Suburb"
                                            variant="outlined"
                                            value={formProperty?.suburb || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, suburb: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="City"
                                            variant="outlined"
                                            value={formProperty?.city || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, city: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Country"
                                            variant="outlined"
                                            value={formProperty?.country || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, country: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="No Bedrooms"
                                            variant="outlined"
                                            value={formProperty?.bedrooms || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty });
                                            }}
                                            //, bedrooms: event.target.value ? parseInt(event.target.value) : undefined, bedrooms: event.target.value ? parseInt(event.target.value) : undefined
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="No Parkings"
                                            variant="outlined"
                                            value={formProperty?.parkings || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty });
                                            }}
                                            //, parkings: event.target.value ? parseInt(event.target.value) : undefined
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked
                                                    checked={formProperty?.furnished || false}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFormProperty({ ...formProperty, furnished: event.target.checked });
                                                    }}
                                                />
                                            }
                                            label="Furnished"
                                        />
                                    </Grid2>
                                </>
                            ) : (
                                <></>
                            )}

                            <PricesComponent prices={prices} listPrices={listPrices}></PricesComponent>
                        </>
                    )}
                </Box>
            </>
        </>
    );
}
