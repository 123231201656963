import { AppBar, Avatar, Stack, Card, Box, Grid, Button, CardActionArea, CardContent, CardMedia, Container, CssBaseline, IconButton, Menu, MenuItem, Pagination, TextField, Toolbar, Tooltip, Typography, useScrollTrigger, FormControlLabel, Checkbox, Rating } from "@mui/material";
import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Property, PropertyPrice, Review } from "../models";
import { PropertyWithOwner } from "../api/QueryProperties";
import { ReviewsComponent } from "../components/ReviewsComponent";
import { PricesComponent } from "../components/PricesComponent";
import { createDescriptionOfArea, createSafetyReportForArea, createThingsToDoInArea, openAiConfiguration } from "../openai/OpenAIClient";
import { OpenAIApi } from "openai";

const DEFAULT_IMAGE = "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80";

interface PropertyPageProps {
    property: PropertyWithOwner;
}

export function PropertyPage(props: PropertyPageProps) {
    const [openAi, setOpenAi] = useState<OpenAIApi | undefined>(undefined);
    const [description, setDescription] = useState("");
    const [thingsToDo, setThingsToDo] = useState("");
    const [safety, setSafety] = useState("");

    useEffect(() => {
        const currentOpenAiClient = new OpenAIApi(openAiConfiguration);
        setOpenAi(currentOpenAiClient);

        (async () => {
            const description = await createDescriptionOfArea(currentOpenAiClient, props.property.suburb, props.property.city, props.property.country);
            setDescription(description);
        })();

        (async () => {
            const safetyReport = await createSafetyReportForArea(currentOpenAiClient, props.property.suburb, props.property.city, props.property.country);
            setSafety(safetyReport);
        })();

        (async () => {
            const thingsToDo = await createThingsToDoInArea(currentOpenAiClient, props.property.suburb, props.property.city, props.property.country);
            setThingsToDo(thingsToDo);
        })();
    }, []);

    return (
        <Grid2 container justifyContent="center" alignItems="center">
            <Card sx={{ width: "90%" }}>
                <Box>
                    <Grid2 container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid2 xs={12}>
                            <CardMedia
                                sx={{
                                    width: "100%",
                                }}
                                component="img"
                                image={props.property.images?.[0] || DEFAULT_IMAGE}
                                alt="Live from space album cover"
                            />
                        </Grid2>
                        <Grid2 xs={12} spacing={1}>
                            <Box>
                                <CardContent>
                                    <Typography align="left" color="text.secondary">
                                        <h2>{props.property.latestPrice?.price ? "R" + props.property.latestPrice.price : ""}</h2>
                                        <h3>{props.property.bedrooms} Bedroom x (Todo add type)</h3>
                                        <p>{props.property.suburb}</p>
                                        <p>{props.property.detail}</p>
                                        <Grid2 container spacing={2}>
                                            <Grid2 xs={3}>Beds ({props.property.bedrooms})</Grid2>
                                            <Grid2 xs={3}>Baths (1)</Grid2>
                                            <Grid2 xs={3}>Park ({props.property.parkings})</Grid2>
                                            <Grid2 xs={3}>78m^2</Grid2>
                                        </Grid2>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Box>
            </Card>
            <Card sx={{ width: "90%" }}>
                <Box>
                    <Grid2 container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid2 xs={12} spacing={1}>
                            <Box>
                                <CardContent>
                                    <Typography align="left" color="text.secondary">
                                        <h1>Description</h1>
                                        <p>{description}</p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Grid2>
                        <Grid2 xs={12} spacing={1}>
                            <Box>
                                <CardContent>
                                    <Typography align="left" color="text.secondary">
                                        <h1>Things to do</h1>
                                        <p>{thingsToDo}</p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Grid2>
                        <Grid2 xs={12} spacing={1}>
                            <Box>
                                <CardContent>
                                    <Typography align="left" color="text.secondary">
                                        <h1>Safety</h1>
                                        <p>{safety}</p>
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Grid2>
                    </Grid2>
                </Box>
            </Card>
            <h2>Prices</h2>
            <Box
                sx={{
                    marginTop: 10,
                }}
            >
                <PricesComponent prices={props.property.prices} listPrices={() => {}} withTopPadding={true}></PricesComponent>
            </Box>
            <h2>Reviews</h2>
            <Box
                sx={{
                    marginTop: 10,
                }}
            >
                <ReviewsComponent reviews={props.property.reviews} listReviews={() => {}} withTopPadding={true}></ReviewsComponent>
            </Box>
        </Grid2>
    );
}
