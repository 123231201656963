import { DataStore, Predicates, RecursiveModelPredicateExtender } from "@aws-amplify/datastore";
import { Owner, Property, PropertyPrice, Review } from "../models";

export interface PropertyWithOwner extends Property {
    owner?: Owner;
    latestPrice: PropertyPrice;
    prices: PropertyPrice[];
}

interface QueryPropertiesProps {
    owner?: Owner;
    filterProperty?: Property;
    pageNumber?: number;
    limit?: number;
}

export async function queryPrices(props: QueryPropertiesProps): Promise<PropertyPrice[]> {
    let models: PropertyPrice[] = [];

    // const pricesCriteria: RecursiveModelPredicateExtender<PropertyPrice> = (price) => {
    //     function addFilters(p: PropertyPrice) {
    //         let myFilterArray = [];
    //         myFilterArray.push(p.streetName.contains(props.filterProperty?.streetName || ""));
    //         myFilterArray.push(p.country.contains(props.filterProperty?.country || ""));
    //         myFilterArray.push(p.city.contains(props.filterProperty?.city || ""));
    //         myFilterArray.push(p.suburb.contains(props.filterProperty?.suburb || ""));

    //         const streetNumber = props.filterProperty?.streetNumber;
    //         if (streetNumber) {
    //             myFilterArray.push(p.streetNumber.eq(streetNumber));
    //         }

    //         const numberOfBedrooms = props.filterProperty?.bedrooms;
    //         if (numberOfBedrooms) {
    //             myFilterArray.push(p.bedrooms.eq(numberOfBedrooms));
    //         }

    //         const numberOfParkings = props.filterProperty?.parkings;
    //         if (numberOfParkings) {
    //             myFilterArray.push(p.parkings.eq(numberOfParkings));
    //         }

    //         if (props.filterProperty?.furnished !== undefined) {
    //             myFilterArray.push(p.furnished.eq(props.filterProperty?.furnished || false));
    //         }

    //         if (props.owner) {
    //             myFilterArray.push(p.ownerID.eq(props.owner?.id));
    //         }

    //         return myFilterArray;
    //     }

    //     let result = property.and((p) => [...addFilters(p)]);
    //     return result;
    // };

    // If we have a limit and pageNumber add these to the request
    if (props.limit !== undefined && props.pageNumber !== undefined) {
        // models = (await DataStore.query<PropertyPrice>(PropertyPrice, pricesCriteria, {
        //     page: props.pageNumber,
        //     limit: props.limit,
        // })) as PropertyPrice[];

        models = (await DataStore.query<PropertyPrice>(PropertyPrice, Predicates.ALL, {
            page: props.pageNumber,
            limit: props.limit,
        })) as PropertyPrice[];
    } else {
        models = (await DataStore.query(PropertyPrice, Predicates.ALL)) as PropertyPrice[];
    }
    return models;
}
