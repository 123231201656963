import { AppBar, Avatar, Stack, Card, Box, Grid, Button, CardActionArea, CardContent, CardMedia, Container, CssBaseline, IconButton, Menu, MenuItem, Pagination, TextField, Toolbar, Tooltip, Typography, useScrollTrigger, FormControlLabel, Checkbox, Rating } from "@mui/material";
import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Property, PropertyPrice, Review } from "../models";
import { PropertyWithOwner } from "../api/QueryProperties";
import { DataStore } from "aws-amplify";
import { queryReviews } from "../api/QueryReviews";

interface ReviewsComponentProps {
    ownerId?: string;
    showButtons?: boolean;
    reviews: Review[];
    withTopPadding?: boolean;
    listReviews: (page?: number) => void;
}

export function ReviewsComponent(props: ReviewsComponentProps) {
    const [page, setPage] = useState(0);
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
        props.listReviews(value - 1);
    };

    return (
        <>
            <Typography>Page: {page + 1}</Typography>
            {props.reviews.map((review: Review, index: number) => {
                return (
                    <>
                        <Card
                            sx={{
                                marginTop: !props.withTopPadding && index === 0 ? 0 : 10,
                            }}
                        >
                            <Box>
                                <Grid2 container justifyContent="center" alignItems="center" spacing={2}>
                                    <Grid2 xs={12}>
                                        <CardMedia
                                            sx={{
                                                width: "100%",
                                            }}
                                            component="img"
                                            image={review.images?.[0] || ""}
                                            alt="Live from space album cover"
                                        />
                                    </Grid2>
                                    <Grid2 xs={12} spacing={1}>
                                        <Box>
                                            <CardContent>
                                                <Typography align="left" color="text.secondary">
                                                    <Grid2 container spacing={2}>
                                                        <Grid2 xs={3}>Owner ({"R" + review.ownerID})</Grid2>
                                                        <Grid2 xs={3}>Property ({review.propertyID})</Grid2>
                                                        <Grid2 xs={3}>
                                                            <Rating value={review.rating || 0} readOnly={true}></Rating>
                                                        </Grid2>
                                                        <Grid2 xs={3}>Text ({review.text})</Grid2>
                                                        <Grid2 xs={3}>Type ({review.type})</Grid2>
                                                        <Grid2 xs={3}>Date ({review.updatedAt})</Grid2>
                                                    </Grid2>
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Card>
                    </>
                );
            })}
            <Typography>Page: {page + 1}</Typography>
            <Pagination count={page + 6} page={page + 1} onChange={handlePaginationChange} />
        </>
    );
}
