import {
    AppBar,
    Avatar,
    Stack,
    Card,
    Box,
    Grid,
    Button,
    CardActionArea,
    CardContent,
    CardMedia,
    Container,
    CssBaseline,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
    useScrollTrigger,
    FormControlLabel,
    Checkbox,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import React, { useCallback, useEffect, useState, MouseEvent } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { LazyProperty, Property, PropertyPrice } from "../models";
import { PropertyPage } from "./PropertyPage";
import { PropertyWithOwner, queryProperties } from "../api/QueryProperties";
import { DataStore, ModelInit } from "@aws-amplify/datastore";
import { PropertyType } from "../models";

const DEFAULT_IMAGE = "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80";

interface PropertiesProps {
    ownerId?: string;
    showButtons?: boolean;
    properties?: PropertyWithOwner[];
}

export function PropertiesPage(props: PropertiesProps) {
    const [page, setPage] = useState(0);
    const [formProperty, setFormProperty] = useState<Partial<Property>>();
    const [properties, setProperties] = useState<PropertyWithOwner[]>(props.properties || []);
    const [propertyToShow, setPropertyToShow] = useState<PropertyWithOwner | undefined>(undefined);

    const [filterProperty, setFilterProperty] = useState<Partial<Property>>();

    const listProperties = useCallback(
        async (currentPage?: number) => {
            const properties: PropertyWithOwner[] = await queryProperties({
                //owner: props.ownerId ? { id: props.ownerId } : undefined
                owner: undefined,
                filterProperty: filterProperty as Property,
                limit: 5,
                pageNumber: currentPage === undefined ? page : currentPage,
            });
            console.log("The properties are: ", properties);
            setProperties(properties);
        },
        [filterProperty, page]
    );

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
        listProperties(value - 1);
    };

    // On page mount list the properties
    useEffect(() => {
        listProperties();
    }, [listProperties]);

    return (
        <>
            <>
                <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                    {propertyToShow ? (
                        <>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPropertyToShow(undefined);
                                }}
                            >
                                Back
                            </Button>
                            <PropertyPage property={propertyToShow} />
                            {
                                //listProperties={listProperties}
                            }
                        </>
                    ) : (
                        <>
                            {props.showButtons === undefined || props.showButtons ? (
                                <>
                                    <Grid2 container spacing={1}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                listProperties();
                                            }}
                                        >
                                            List
                                        </Button>
                                        <TextField
                                            id="outlined-basic"
                                            label="Owner ID"
                                            variant="outlined"
                                            value={filterProperty?.ownerID || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, ownerID: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Street Name"
                                            variant="outlined"
                                            value={filterProperty?.streetName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, streetName: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Street Number"
                                            variant="outlined"
                                            value={filterProperty?.streetNumber || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, streetNumber: event.target.value ? parseInt(event.target.value) : undefined });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Suburb"
                                            variant="outlined"
                                            value={filterProperty?.suburb || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, suburb: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="City"
                                            variant="outlined"
                                            value={filterProperty?.city || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, city: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Country"
                                            variant="outlined"
                                            value={filterProperty?.country || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, country: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="No Bedrooms"
                                            variant="outlined"
                                            value={filterProperty?.bedrooms || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, bedrooms: event.target.value ? parseInt(event.target.value) + "" : undefined });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="No Parkings"
                                            variant="outlined"
                                            value={filterProperty?.parkings || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterProperty({ ...filterProperty, parkings: event.target.value ? parseInt(event.target.value) + "" : undefined });
                                            }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked
                                                    checked={filterProperty?.furnished || false}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFilterProperty({ ...filterProperty, furnished: event.target.checked });
                                                    }}
                                                />
                                            }
                                            label="Furnished"
                                        />
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            placeholder="Review Type"
                                            value={filterProperty?.type || ""}
                                            label="Review Type"
                                            onChange={(event: SelectChangeEvent<"" | PropertyType | "BUY" | "RENTAL" | "REVIEW">) => {
                                                setFilterProperty({ ...filterProperty, type: event.target.value as PropertyType });
                                            }}
                                        >
                                            <MenuItem value={undefined}>{"Reset"}</MenuItem>
                                            <MenuItem value={PropertyType.BUY}>{PropertyType.BUY}</MenuItem>
                                            <MenuItem value={PropertyType.RENTAL}>{PropertyType.RENTAL}</MenuItem>
                                            <MenuItem value={PropertyType.REVIEW}>{PropertyType.REVIEW}</MenuItem>
                                        </Select>
                                    </Grid2>
                                    <br />
                                    <Grid2 container spacing={1}>
                                        <Button
                                            variant="contained"
                                            onClick={async () => {
                                                console.log("GOing to create this thing ", formProperty);
                                                // TODO: Add validation
                                                if (formProperty) {
                                                    console.log("Creating   ", formProperty);
                                                    const result = await DataStore.save<LazyProperty>(new Property(formProperty as ModelInit<LazyProperty>));
                                                    console.log("Result is  ", result);
                                                }
                                                //await createProperty(new Property((formProperty as Property) || {}));
                                                listProperties();
                                            }}
                                        >
                                            Create
                                        </Button>
                                        <Button variant="contained">Update</Button>
                                        <Button variant="contained">Delete</Button>
                                        <TextField
                                            id="outlined-basic"
                                            label="Owner ID"
                                            variant="outlined"
                                            value={formProperty?.ownerID || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, ownerID: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Street Name"
                                            variant="outlined"
                                            value={formProperty?.streetName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, streetName: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Street Number"
                                            variant="outlined"
                                            value={formProperty?.streetNumber || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, streetNumber: event.target.value ? parseInt(event.target.value) : undefined });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Suburb"
                                            variant="outlined"
                                            value={formProperty?.suburb || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, suburb: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="City"
                                            variant="outlined"
                                            value={formProperty?.city || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, city: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Country"
                                            variant="outlined"
                                            value={formProperty?.country || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, country: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="No Bedrooms"
                                            variant="outlined"
                                            value={formProperty?.bedrooms || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, bedrooms: event.target.value ? parseInt(event.target.value) + "" : undefined });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="No Parkings"
                                            variant="outlined"
                                            value={formProperty?.parkings || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormProperty({ ...formProperty, parkings: event.target.value ? parseInt(event.target.value) + "" : undefined });
                                            }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    defaultChecked
                                                    checked={formProperty?.furnished || false}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFormProperty({ ...formProperty, furnished: event.target.checked });
                                                    }}
                                                />
                                            }
                                            label="Furnished"
                                        />
                                    </Grid2>
                                </>
                            ) : (
                                <></>
                            )}

                            <>
                                <Typography>Page: {page + 1}</Typography>
                                {properties.map((property: PropertyWithOwner, index: number) => {
                                    return (
                                        <>
                                            <Card
                                                sx={{
                                                    marginTop: index === 0 ? 0 : 10,
                                                }}
                                            >
                                                <Box>
                                                    <Grid2 container justifyContent="center" alignItems="center" spacing={2}>
                                                        <Grid2 xs={12} md={5}>
                                                            <CardActionArea
                                                                onClick={() => {
                                                                    setPropertyToShow(property);
                                                                }}
                                                            >
                                                                <CardMedia
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                    component="img"
                                                                    image={property.images?.[0] || DEFAULT_IMAGE}
                                                                    alt="Live from space album cover"
                                                                />
                                                            </CardActionArea>
                                                        </Grid2>
                                                        <Grid2 xs={12} md={7} spacing={1}>
                                                            <Box>
                                                                <CardContent>
                                                                    <Typography align="left" color="text.secondary">
                                                                        {
                                                                            // TODO: Add Price to api
                                                                        }
                                                                        <h2>{property.latestPrice?.price ? "R" + property.latestPrice?.price : ""}</h2>
                                                                        <h3>{property.bedrooms} Bedroom x (Todo add type)</h3>
                                                                        <p>{property.suburb}</p>
                                                                        {
                                                                            // TODO: Add description to api
                                                                        }
                                                                        <p>{property.detail}</p>
                                                                        <Grid2 container spacing={2}>
                                                                            <Grid2 xs={3}>Beds ({property.bedrooms})</Grid2>
                                                                            {
                                                                                // TODO: Add bathrooms to api
                                                                            }
                                                                            <Grid2 xs={3}>Baths ({property.bedrooms})</Grid2>
                                                                            <Grid2 xs={3}>Park ({property.parkings})</Grid2>
                                                                            {
                                                                                // TODO: Add size to api
                                                                            }
                                                                            <Grid2 xs={3}>({property.bedrooms})m^2</Grid2>
                                                                        </Grid2>
                                                                    </Typography>
                                                                </CardContent>
                                                            </Box>
                                                        </Grid2>
                                                    </Grid2>
                                                </Box>
                                            </Card>
                                        </>
                                    );
                                })}
                                <Typography>Page: {page + 1}</Typography>
                                <Pagination count={page + 6} page={page + 1} onChange={handlePaginationChange} />
                            </>
                        </>
                    )}
                </Box>
            </>
        </>
    );
}
