// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PropertyType = {
  "RENTAL": "RENTAL",
  "BUY": "BUY",
  "REVIEW": "REVIEW"
};

const ReviewType = {
  "PROPERTY": "PROPERTY",
  "LANDLORD": "LANDLORD",
  "AGENT": "AGENT",
  "AREA": "AREA"
};

const { Owner, Review, PropertyPrice, Property } = initSchema(schema);

export {
  Owner,
  Review,
  PropertyPrice,
  Property,
  PropertyType,
  ReviewType
};