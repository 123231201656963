import { Card, Box, Button, CardActionArea, CardContent, Pagination, TextField, Toolbar, Tooltip, Typography, useScrollTrigger, FormControlLabel, Checkbox } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { LazyOwner, Owner } from "../../models";
import { queryOwners } from "../../api/QueryOwners";
import { DataStore, ModelInit } from "@aws-amplify/datastore";

const DEFAULT_IMAGE = "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80";

interface OwnersPageProps {
    ownerId?: string;
    showButtons?: boolean;
}

export function OwnersPage(props: OwnersPageProps) {
    const [page, setPage] = useState(0);
    const [formOwner, setFormOwner] = useState<Partial<Owner>>();
    const [owners, setOwners] = useState<Owner[]>([]);
    const [ownerToShow, setOwnerToShow] = useState<Owner | undefined>(undefined);

    const [filterOwner, setFilterOwner] = useState<Partial<Owner>>();

    const listOwners = useCallback(
        async (currentPage?: number) => {
            const owners: Owner[] = await queryOwners({
                filterOwner: filterOwner as Owner,
                limit: 5,
                pageNumber: currentPage === undefined ? page : currentPage,
            });
            console.log("The owners are: ", owners);
            setOwners(owners);
        },
        [filterOwner, page]
    );

    const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
        listOwners(value - 1);
    };

    // On page mount list the properties
    useEffect(() => {
        listOwners();
    }, [listOwners]);

    return (
        <>
            <>
                <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                    {ownerToShow ? (
                        <>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setOwnerToShow(undefined);
                                }}
                            >
                                Back
                            </Button>
                            {/* <PropertyPage property={ownerToShow} /> */}
                            {
                                //listOwners={listOwners}
                            }
                        </>
                    ) : (
                        <>
                            {props.showButtons === undefined || props.showButtons ? (
                                <>
                                    <Grid2 container spacing={1}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                listOwners();
                                            }}
                                        >
                                            List
                                        </Button>
                                        <TextField
                                            id="outlined-basic"
                                            label="Owner ID"
                                            variant="outlined"
                                            value={filterOwner?.id || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterOwner({ ...filterOwner, id: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="First Name"
                                            variant="outlined"
                                            value={filterOwner?.firstName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterOwner({ ...filterOwner, firstName: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Last Name"
                                            variant="outlined"
                                            value={filterOwner?.lastName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterOwner({ ...filterOwner, lastName: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Company"
                                            variant="outlined"
                                            value={filterOwner?.companyName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFilterOwner({ ...filterOwner, companyName: event.target.value || "" });
                                            }}
                                        />
                                    </Grid2>
                                    <br />
                                    <Grid2 container spacing={1}>
                                        <Button
                                            variant="contained"
                                            onClick={async () => {
                                                console.log("GOing to create this thing ", formOwner);
                                                if (formOwner && formOwner?.firstName && formOwner.lastName) {
                                                    console.log("Creating   ", formOwner);
                                                    const result = await DataStore.save<LazyOwner>(new Owner(formOwner as ModelInit<LazyOwner>));
                                                    console.log("Result is  ", result);
                                                }
                                                listOwners();
                                            }}
                                        >
                                            Create
                                        </Button>
                                        <Button variant="contained">Update</Button>
                                        <Button variant="contained">Delete</Button>
                                        <TextField
                                            id="outlined-basic"
                                            label="Owner ID"
                                            variant="outlined"
                                            value={formOwner?.id || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormOwner({ ...formOwner, id: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="First Name"
                                            variant="outlined"
                                            value={formOwner?.firstName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormOwner({ ...formOwner, firstName: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Last Name"
                                            variant="outlined"
                                            value={formOwner?.lastName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormOwner({ ...formOwner, lastName: event.target.value || "" });
                                            }}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            label="Company Name"
                                            variant="outlined"
                                            value={formOwner?.companyName || ""}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                setFormOwner({ ...formOwner, companyName: event.target.value || "" });
                                            }}
                                        />
                                    </Grid2>
                                </>
                            ) : (
                                <></>
                            )}

                            <>
                                <Typography>Page: {page + 1}</Typography>
                                {owners.map((owner: Owner, index: number) => {
                                    return (
                                        <>
                                            <Card
                                                sx={{
                                                    marginTop: index === 0 ? 0 : 10,
                                                }}
                                            >
                                                <Box>
                                                    <Grid2 container justifyContent="center" alignItems="center" spacing={2}>
                                                        <Grid2 xs={12} md={5}>
                                                            <CardActionArea
                                                                onClick={() => {
                                                                    setOwnerToShow(owner);
                                                                }}
                                                            >
                                                                Avatar
                                                            </CardActionArea>
                                                        </Grid2>
                                                        <Grid2 xs={12} md={7} spacing={1}>
                                                            <Box>
                                                                <CardContent>
                                                                    <Typography align="left" color="text.secondary">
                                                                        {
                                                                            // TODO: Add Price to api
                                                                        }
                                                                        <h4>OwnerId: {owner.id}</h4>
                                                                        <h2>First Name: {owner.firstName}</h2>
                                                                        <h3>Last Name: {owner.lastName}</h3>
                                                                        {owner.companyName ? (
                                                                            <>
                                                                                <h3>Company Name: {owner.companyName}</h3>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </Typography>
                                                                </CardContent>
                                                            </Box>
                                                        </Grid2>
                                                    </Grid2>
                                                </Box>
                                            </Card>
                                        </>
                                    );
                                })}
                                <Typography>Page: {page + 1}</Typography>
                                <Pagination count={page + 6} page={page + 1} onChange={handlePaginationChange} />
                            </>
                        </>
                    )}
                </Box>
            </>
        </>
    );
}
