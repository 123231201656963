import { AppBar, ThemeProvider, createTheme, Avatar, Box, Button, Container, CssBaseline, IconButton, Menu, MenuItem, Toolbar, Tooltip, Typography, useScrollTrigger } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { PropertiesPage as PropertiesPageOld } from "./pages/original/PropertiesPage";
import { PropertiesPage } from "./pages/PropertiesPage";
import React, { useState, MouseEvent, useMemo } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { AmplifyUser } from "@aws-amplify/ui";
import { PricesPage } from "./pages/original/PricesPage";
import { ReviewsPage } from "./pages/original/ReviewsPage";
import { OwnersPage } from "./pages/original/OwnersPage";
import { AmplifyUIComponents } from "./pages/AmplifyUIComponents";
import logo from "./logo.png";

interface ElevationScrollProps {
    children: React.ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window : undefined,
    });

    return React.cloneElement(props.children, {
        elevation: trigger ? 4 : 0,
    });
}

const pages = ["Owners", "Properties", "Properties2", "Prices", "Reviews", "Amplify UI Components"];
const settings = ["Toggle Dark Mode", "Logout"];

interface AppProps {
    user?: AmplifyUser;
    signOut?: () => void;
}

function App(props: AppProps) {
    const [darkMode, setDarkMode] = useState(true);
    const [showOwners, setShowOwners] = useState(false);
    const [showPrices, setShowPrices] = useState(false);
    const [showProperties, setShowProperties] = useState(false);
    const [showProperties2, setShowProperties2] = useState(false);
    const [showReviews, setShowReviews] = useState(false);
    const [showAmplifyUIComponents, setShowAmplifyUIComponents] = useState(false);

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const darkTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? "dark" : "light",
                },
                components: {
                    MuiMenu: {
                        styleOverrides: {
                            list: {
                                '&[role="menu"]': {
                                    // backgroundColor: "black",
                                    // color: "black",
                                },
                            },
                        },
                    },
                },
            }),
        [darkMode]
    );

    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (page: string) => {
        setAnchorElNav(null);
        console.log("PAGE   ", page);
        // Handle user input
        switch (page) {
            case "Owners":
                setShowOwners(true);
                setShowProperties(false);
                setShowProperties2(false);
                setShowPrices(false);
                setShowReviews(false);
                setShowAmplifyUIComponents(false);
                break;
            case "Properties":
                setShowOwners(false);
                setShowProperties(true);
                setShowProperties2(false);
                setShowPrices(false);
                setShowReviews(false);
                setShowAmplifyUIComponents(false);
                break;
            case "Properties2":
                setShowOwners(false);
                setShowProperties(false);
                setShowProperties2(true);
                setShowPrices(false);
                setShowReviews(false);
                setShowAmplifyUIComponents(false);
                break;
            case "Prices":
                setShowOwners(false);
                setShowProperties(false);
                setShowProperties2(false);
                setShowPrices(true);
                setShowReviews(false);
                setShowAmplifyUIComponents(false);
                break;
            case "Reviews":
                setShowOwners(false);
                setShowProperties(false);
                setShowProperties2(false);
                setShowPrices(false);
                setShowReviews(true);
                setShowAmplifyUIComponents(false);
                break;
            case "Amplify UI Components":
                setShowOwners(false);
                setShowProperties(false);
                setShowProperties2(false);
                setShowPrices(false);
                setShowReviews(false);
                setShowAmplifyUIComponents(true);
                break;
            default:
                setShowOwners(false);
                setShowProperties(false);
                setShowProperties2(false);
                setShowPrices(false);
                setShowReviews(false);
                setShowAmplifyUIComponents(false);
                break;
        }
    };

    const handleCloseUserMenu = (setting: string, signOut?: () => void) => {
        setAnchorElUser(null);

        // Handle user input
        switch (setting) {
            case "Logout":
                if (signOut) {
                    signOut();
                }
                break;
            case "Toggle Dark Mode":
                setDarkMode(!darkMode);
                break;
            default:
                break;
        }
    };

    return (
        <div className="App" style={{ backgroundColor: darkTheme.palette.mode === "dark" ? "black" : "#FFFFFA" }}>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <ElevationScroll>
                    <AppBar style={{ backgroundColor: darkTheme.palette.mode === "dark" ? "black" : "#FFFFFA" }}>
                        <Toolbar>
                            <img
                                alt="PropView logo"
                                src={logo}
                                style={{
                                    maxWidth: 80,
                                }}
                            />
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                onClick={() => {
                                    handleCloseNavMenu("Home");
                                }}
                                color={darkTheme.palette.mode === "dark" ? "white" : "black"}
                                sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                            >
                                Propview
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                <IconButton size="large" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu}>
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={() => {
                                        handleCloseNavMenu("Home");
                                    }}
                                    sx={{
                                        display: { xs: "block", md: "none" },
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem
                                            key={page}
                                            onClick={() => {
                                                handleCloseNavMenu(page);
                                            }}
                                        >
                                            <Typography textAlign="center">{page}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                Propview
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                                {pages.map((page) => (
                                    <Button
                                        key={page}
                                        onClick={() => {
                                            handleCloseNavMenu(page);
                                        }}
                                        sx={{ my: 2, color: darkTheme.palette.mode === "dark" ? "white" : "black", display: "block" }}
                                    >
                                        {page}
                                    </Button>
                                ))}
                            </Box>
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                        <Avatar alt="Remy Sharp" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={() => {
                                        handleCloseUserMenu("", props?.signOut);
                                    }}
                                >
                                    <MenuItem>
                                        <Typography textAlign="center">User: {props?.user?.attributes?.name + " " + props?.user?.attributes?.family_name}</Typography>
                                    </MenuItem>
                                    {settings.map((setting) => (
                                        <MenuItem
                                            key={setting}
                                            onClick={() => {
                                                handleCloseUserMenu(setting, props?.signOut);
                                            }}
                                        >
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                <Toolbar />
                <div style={{ backgroundColor: darkTheme.palette.mode === "dark" ? "black" : "#FFFFFA" }}>
                    <Container style={{ backgroundColor: darkTheme.palette.mode === "dark" ? "black" : "#FFFFFA" }}>
                        {showOwners ? <OwnersPage></OwnersPage> : <></>}
                        {showProperties ? <PropertiesPageOld></PropertiesPageOld> : <></>}
                        {showProperties2 ? <PropertiesPage></PropertiesPage> : <></>}
                        {showPrices ? <PricesPage></PricesPage> : <></>}
                        {showReviews ? <ReviewsPage></ReviewsPage> : <></>}

                        {showAmplifyUIComponents ? <AmplifyUIComponents></AmplifyUIComponents> : <></>}
                        {/* <OwnerCreateForm></OwnerCreateForm>
                        <OwnerUpdateForm></OwnerUpdateForm>
                        <ReviewCreateForm></ReviewCreateForm>
                        <ReviewUpdateForm></ReviewUpdateForm> */}
                    </Container>
                </div>
            </ThemeProvider>
        </div>
    );
}

export default withAuthenticator(App);
