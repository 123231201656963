import React, { useEffect } from "react";
import { OwnerCreateForm, OwnerUpdateForm, ReviewCreateForm, ReviewUpdateForm, studioTheme } from "../ui-components/index";

interface AmplifyUIComponentsProps {}

export function AmplifyUIComponents(props: AmplifyUIComponentsProps) {
    return (
        <>
            <h1>Owner Create Form</h1>
            <OwnerCreateForm></OwnerCreateForm>
            <h1>Owner Update Form</h1>
            <OwnerUpdateForm></OwnerUpdateForm>
            <h1>Review Create Form</h1>
            <ReviewCreateForm></ReviewCreateForm>
            <h1>Review Update Form</h1>
            <ReviewUpdateForm></ReviewUpdateForm>
        </>
    );
}
