import { DataStore, RecursiveModelPredicateExtender } from "@aws-amplify/datastore";
import { Owner, Property, PropertyPrice, Review } from "../models";

export interface PropertyWithOwner extends Property {
    owner?: Owner;
    latestPrice: PropertyPrice;
    prices: PropertyPrice[];
    reviews: Review[];
    averageReview?: number;
}

interface QueryPropertiesProps {
    owner?: Owner;
    filterProperty?: Property;
    pageNumber?: number;
    limit?: number;
}

export async function queryProperties(props: QueryPropertiesProps): Promise<PropertyWithOwner[]> {
    let models: Property[] = [];

    const propertyCriteria: RecursiveModelPredicateExtender<Property> = (property) => {
        function addFilters(p: any) {
            let myFilterArray = [];
            myFilterArray.push(p.streetName.contains(props.filterProperty?.streetName || ""));
            myFilterArray.push(p.country.contains(props.filterProperty?.country || ""));
            myFilterArray.push(p.city.contains(props.filterProperty?.city || ""));
            myFilterArray.push(p.suburb.contains(props.filterProperty?.suburb || ""));

            const ownerId = props.filterProperty?.ownerID;
            if (ownerId) {
                myFilterArray.push(p.ownerID.eq(ownerId));
            }

            const streetNumber = props.filterProperty?.streetNumber;
            if (streetNumber) {
                myFilterArray.push(p.streetNumber.eq(streetNumber));
            }

            const numberOfBedrooms = props.filterProperty?.bedrooms;
            if (numberOfBedrooms) {
                myFilterArray.push(p.bedrooms.eq(numberOfBedrooms));
            }

            const numberOfParkings = props.filterProperty?.parkings;
            if (numberOfParkings) {
                myFilterArray.push(p.parkings.eq(numberOfParkings));
            }

            if (props.filterProperty?.type) {
                myFilterArray.push(p.type.eq(props.filterProperty?.type));
            }

            if (props.filterProperty?.furnished !== undefined) {
                myFilterArray.push(p.furnished.eq(props.filterProperty?.furnished || false));
            }

            if (props.owner) {
                myFilterArray.push(p.ownerID.eq(props.owner?.id));
            }

            return myFilterArray;
        }

        let result = property.and((p) => [...addFilters(p)]);
        return result;
    };

    // If we have a limit and pageNumber add these to the request
    if (props.limit !== undefined && props.pageNumber !== undefined) {
        models = (await DataStore.query<Property>(Property, propertyCriteria, {
            page: props.pageNumber,
            limit: props.limit,
        })) as Property[];
    } else {
        models = (await DataStore.query(Property, propertyCriteria)) as Property[];
    }

    // // TODO: Remove these and rather move to only showing top level average price and review
    // // Further reviews can be pulled in a table usin pagination
    // let propertyReviews: Review[] = await DataStore.query(Review);
    // for (let x = 0; x < models.length; x++) {
    //     for (let y = 0; y < propertyReviews.length; y++) {
    //         if (models[x].id === propertyReviews[y].propertyID) {
    //             models[x] = Property.copyOf(models[x], (updated) => {
    //                 updated.Reviews = [...(models[x].Reviews || []), propertyReviews[y]];
    //             }) as PropertyWithOwner;
    //         }
    //     }
    // }

    let propertiesWithOwners: PropertyWithOwner[] = [];
    for (let x = 0; x < models.length; x++) {
        //models[x].prices = await models[x].Prices.toArray();

        let propertyPrices: PropertyPrice[] = await DataStore.query(PropertyPrice, (c) => c.propertyID.eq(models[x].id), {
            sort: (c) => c.date("DESCENDING"),
        });

        propertiesWithOwners[x] = { ...models[x], reviews: [], prices: propertyPrices, latestPrice: propertyPrices[0] };
    }

    for (let x = 0; x < propertiesWithOwners.length; x++) {
        let reviews: Review[] = await DataStore.query(Review, (c) => c.propertyID.eq(propertiesWithOwners[x].id), {
            sort: (c) => c.updatedAt("DESCENDING"),
        });

        // Calculate the average review
        let totalReviews = 0;
        reviews.map((review) => {
            totalReviews += review.rating;
        });

        propertiesWithOwners[x] = { ...propertiesWithOwners[x], reviews: reviews, averageReview: totalReviews / reviews.length };
    }

    // // TODO: Likely only a single owner per property
    // // But update this to filter by the property itself
    // let owners: Owner[] = await DataStore.query(Owner);
    // for (let x = 0; x < models.length; x++) {
    //     for (let y = 0; y < owners.length; y++) {
    //         if (models[x].ownerID === owners[y].id) {
    //             models[x] = { ...models[x], owner: owners[y] };
    //             continue;
    //         }
    //     }
    // }

    return propertiesWithOwners;
}
