import { DataStore, Predicates, RecursiveModelPredicateExtender, RecursiveModelPredicate } from "@aws-amplify/datastore";
import { LazyReview, Owner, Property, PropertyPrice, Review } from "../models";

interface QueryReviewsProps {
    filterReview?: Review;
    pageNumber?: number;
    limit?: number;
}

export async function queryReviews(props: QueryReviewsProps): Promise<Review[]> {
    let models: Review[] = [];

    const reviewCriteria: RecursiveModelPredicateExtender<Review> = (owner) => {
        function addFilters(p: RecursiveModelPredicate<LazyReview>) {
            let myFilterArray = [];

            if (props.filterReview?.rating) {
                myFilterArray.push(p.rating.ge(props.filterReview?.rating));
            }

            if (props.filterReview?.streetName) {
                myFilterArray.push(p.streetName.eq(props.filterReview?.streetName));
            }

            if (props.filterReview?.country) {
                myFilterArray.push(p.country.eq(props.filterReview?.country));
            }

            if (props.filterReview?.city) {
                myFilterArray.push(p.city.eq(props.filterReview?.city));
            }

            if (props.filterReview?.suburb) {
                myFilterArray.push(p.suburb.eq(props.filterReview?.suburb));
            }

            if (props.filterReview?.ownerID) {
                myFilterArray.push(p.ownerID.eq(props.filterReview?.ownerID));
            }

            if (props.filterReview?.propertyID) {
                myFilterArray.push(p.propertyID.eq(props.filterReview?.propertyID));
            }

            if (props.filterReview?.streetNumber) {
                myFilterArray.push(p.streetNumber.eq(props.filterReview?.streetNumber));
            }

            if (props.filterReview?.type) {
                myFilterArray.push(p.type.contains(props.filterReview?.type));
            }

            return myFilterArray;
        }

        let result = owner.and((o) => [...addFilters(o)]);
        return result;
    };

    // If we have a limit and pageNumber add these to the request
    if (props.limit !== undefined && props.pageNumber !== undefined) {
        models = (await DataStore.query<Review>(Review, reviewCriteria, {
            page: props.pageNumber,
            limit: props.limit,
        })) as Review[];
    } else {
        models = (await DataStore.query(Review, reviewCriteria)) as Review[];
    }
    return models;
}
