import { Box, Button, MenuItem, TextField, Select, SelectChangeEvent, InputLabel, Rating } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import { LazyReview, Review, ReviewType } from "../../models";
import { queryReviews } from "../../api/QueryReviews";
import { ReviewsComponent } from "../../components/ReviewsComponent";
import { DataStore, ModelInit } from "@aws-amplify/datastore";
import { CreateReviewForm } from "./CreateReviewForm";

interface ReviewsProps {
    ownerId?: string;
    showButtons?: boolean;
}

export function ReviewsPage(props: ReviewsProps) {
    const [page, setPage] = useState(0);
    const [showCreateReview, setShowCreateReview] = useState(false);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [reviewToShow, setReviewToShow] = useState<Review | undefined>(undefined);

    const [filterReview, setFilterReview] = useState<Partial<Review>>();

    const listReviews = useCallback(
        async (currentPage?: number) => {
            const reviews: Review[] = await queryReviews({
                filterReview: filterReview as Review,
                limit: 5,
                pageNumber: currentPage === undefined ? page : currentPage,
            });
            console.log("The reviews are: ", reviews);
            setReviews(reviews);
        },
        [filterReview, page]
    );

    // On page mount list the properties
    useEffect(() => {
        listReviews();
    }, [listReviews]);

    return (
        <>
            <>
                {showCreateReview ? (
                    <CreateReviewForm
                        toggleShowReviewPage={() => {
                            setShowCreateReview(false);
                        }}
                    ></CreateReviewForm>
                ) : (
                    <Box sx={{ mt: 3, ml: 1, mb: 1 }}>
                        {reviewToShow ? (
                            <>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setReviewToShow(undefined);
                                    }}
                                >
                                    Back
                                </Button>
                            </>
                        ) : (
                            <>
                                {props.showButtons === undefined || props.showButtons ? (
                                    <>
                                        <Grid2 container spacing={1}>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setShowCreateReview(true);
                                                }}
                                            >
                                                Create Review
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    listReviews();
                                                }}
                                            >
                                                List
                                            </Button>
                                            <TextField
                                                id="outlined-basic"
                                                label="Owner ID"
                                                variant="outlined"
                                                value={filterReview?.ownerID || ""}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFilterReview({ ...filterReview, ownerID: event.target.value || "" });
                                                }}
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="Property ID"
                                                variant="outlined"
                                                value={filterReview?.propertyID || ""}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFilterReview({ ...filterReview, propertyID: event.target.value || "" });
                                                }}
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="Street Name"
                                                variant="outlined"
                                                value={filterReview?.streetName || ""}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFilterReview({ ...filterReview, streetName: event.target.value || "" });
                                                }}
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="Street Number"
                                                variant="outlined"
                                                value={filterReview?.streetNumber || ""}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFilterReview({ ...filterReview, streetNumber: event.target.value ? parseInt(event.target.value) : undefined });
                                                }}
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="Suburb"
                                                variant="outlined"
                                                value={filterReview?.suburb || ""}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFilterReview({ ...filterReview, suburb: event.target.value || "" });
                                                }}
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="City"
                                                variant="outlined"
                                                value={filterReview?.city || ""}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFilterReview({ ...filterReview, city: event.target.value || "" });
                                                }}
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="Country"
                                                variant="outlined"
                                                value={filterReview?.country || ""}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFilterReview({ ...filterReview, country: event.target.value || "" });
                                                }}
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="Text"
                                                variant="outlined"
                                                value={filterReview?.text || ""}
                                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFilterReview({ ...filterReview, text: event.target.value || "" });
                                                }}
                                            />
                                            <Rating
                                                value={filterReview?.rating || 0}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setFilterReview({ ...filterReview, rating: newValue });
                                                    }
                                                }}
                                            ></Rating>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                placeholder="Review Type"
                                                value={filterReview?.type || ""}
                                                label="Review Type"
                                                onChange={(event: SelectChangeEvent<"" | ReviewType | "PROPERTY" | "LANDLORD" | "AGENT" | "AREA">) => {
                                                    setFilterReview({ ...filterReview, type: event.target.value as ReviewType });
                                                }}
                                            >
                                                <MenuItem value={undefined}>{"Reset"}</MenuItem>
                                                <MenuItem value={ReviewType.AGENT}>{ReviewType.AGENT}</MenuItem>
                                                <MenuItem value={ReviewType.AREA}>{ReviewType.AREA}</MenuItem>
                                                <MenuItem value={ReviewType.LANDLORD}>{ReviewType.LANDLORD}</MenuItem>
                                                <MenuItem value={ReviewType.PROPERTY}>{ReviewType.PROPERTY}</MenuItem>
                                            </Select>
                                        </Grid2>
                                        <br />
                                    </>
                                ) : (
                                    <></>
                                )}
                                <ReviewsComponent reviews={reviews} listReviews={listReviews}></ReviewsComponent>
                            </>
                        )}
                    </Box>
                )}
            </>
        </>
    );
}
