import { DataStore, RecursiveModelPredicateExtender } from "@aws-amplify/datastore";
import { Owner } from "../models";

interface QueryOwnerProps {
    filterOwner: Owner;
    limit?: number;
    pageNumber?: number;
}

export async function queryOwners(props: QueryOwnerProps): Promise<Owner[]> {
    let owners: Owner[] = [];

    const ownerCriteria: RecursiveModelPredicateExtender<Owner> = (owner) => {
        function addFilters(p: any) {
            let myFilterArray = [];
            myFilterArray.push(p.id.contains(props.filterOwner?.id || ""));
            myFilterArray.push(p.firstName.contains(props.filterOwner?.firstName || ""));
            myFilterArray.push(p.lastName.contains(props.filterOwner?.lastName || ""));

            const companyName = props.filterOwner?.companyName;
            if (companyName) {
                myFilterArray.push(p.companyName.contains(props.filterOwner?.companyName || ""));
            }

            return myFilterArray;
        }

        let result = owner.and((o) => [...addFilters(o)]);
        return result;
    };

    // If we have a limit and pageNumber add these to the request
    if (props.limit !== undefined && props.pageNumber !== undefined) {
        owners = (await DataStore.query(Owner, ownerCriteria, {
            page: props.pageNumber,
            limit: props.limit,
        })) as Owner[];
    } else {
        owners = (await DataStore.query(Owner, ownerCriteria)) as Owner[];
    }

    return owners;
}
