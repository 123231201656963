import { Configuration, OpenAIApi } from "openai";
import { AreaSafetyCache, AreaToDoCache, AreaDescriptionsCache } from "./AreaDescriptions";

//
export const openAiConfiguration = new Configuration({
    apiKey: "",
});

export const mockMode = () => {
    if (openAiConfiguration.apiKey === "") {
        return "mock";
    }

    return "run";
};

function formatAreaForGpt(suburb: string, city: string, country: string) {
    return suburb + ", " + city + ", " + country;
}

function formatAreaAsKey(suburb: string, city: string, country: string) {
    return suburb.toUpperCase().replaceAll(" ", "_") + "_" + city.toUpperCase().replaceAll(" ", "_") + "_" + country.toUpperCase().replaceAll(" ", "_");
}

// TODO: Sanitize the text to avoid prompt injection
// Also would need to validate that the area is a real area
// and not a prompt injection area
export async function createSafetyReportForArea(openAiClient: OpenAIApi, suburb: string, city: string, country: string): Promise<string> {
    const cachedText = AreaSafetyCache[formatAreaAsKey(suburb, city, country)];
    if (cachedText) {
        return cachedText;
    }

    if (mockMode() === "mock") {
        return "Currently in mock mode, no completion will be run";
    }

    const result = await openAiClient.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
            { role: "system", content: "You are a safety assistant helping to summarize the safety of an area using data such as Numbeo and SAPS. You will provide a safety score, list common crimes in the area and tips to avoid them." },
            { role: "user", content: formatAreaForGpt(suburb, city, country) },
        ],
    });

    if (result.status !== 200) {
        return "";
    }

    return result.data.choices[0].message?.content || "";
}

export async function createDescriptionOfArea(openAiClient: OpenAIApi, suburb: string, city: string, country: string): Promise<string> {
    const cachedText = AreaDescriptionsCache[formatAreaAsKey(suburb, city, country)];
    if (cachedText) {
        return cachedText;
    }

    if (mockMode() === "mock") {
        return "Currently in mock mode, no completion will be run";
    }

    const result = await openAiClient.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
            { role: "system", content: "You are a local expert that lists the top activities and restaurants in an area that only locals would know. You are giving advise to tourists that want to explore and enjoy an area and learn how the locals have fun." },
            { role: "user", content: formatAreaForGpt(suburb, city, country) },
        ],
    });

    if (result.status !== 200) {
        return "";
    }

    return result.data.choices[0].message?.content || "";
}

export async function createThingsToDoInArea(openAiClient: OpenAIApi, suburb: string, city: string, country: string): Promise<string> {
    const cachedText = AreaToDoCache[formatAreaAsKey(suburb, city, country)];
    if (cachedText) {
        return cachedText;
    }

    if (mockMode() === "mock") {
        return "Currently in mock mode, no completion will be run";
    }

    const result = await openAiClient.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [
            { role: "system", content: "You are a local expert that lists the top activities and restaurants in an area that only locals would know. You are giving advise to tourists that want to explore and enjoy an area and learn how the locals have fun." },
            { role: "user", content: formatAreaForGpt(suburb, city, country) },
        ],
    });

    if (result.status !== 200) {
        return "";
    }

    return result.data.choices[0].message?.content || "";
}
